import React from 'react';
import Layout from '../components/layout';
import 'bootstrap';
import { graphql } from 'gatsby';

class Calculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payRate: 0.00,
      otRate: 1.50,
      otHourly: 0.00,
      grossPay: 0.00,
      taxRate: 0.12,
      deductions: 0.00,
      payCheck: 0.00,
      hours: 0.00,
      otHours: 0.00,
      totalTaxes: 0.00
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value });

    this.setState((state) => {
      state.otHourly = state.otRate * state.payRate;
      state.grossPay = (state.hours * state.payRate) +
      (state.otHours * state.otHourly)
      state.totalTaxes = state.grossPay * state.taxRate;
      state.payCheck = state.grossPay - state.totalTaxes - state.deductions;
    })
  }

  render() {
    const { data, location } = this.props;
    
    return (
      <Layout data={data} location={location}>
        <div className="row">
          <h2 className="page-title">{data.markdownRemark.frontmatter.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
        </div>
        <div className="row justify-content-center">
          <div className="m-3 article-img-bg ">
            <img src="/images/calculator.png" alt=""/>
          </div>
          <div className="calc m-3 card pt-4 col-8">
            <form className="paycheck" name="paycheck">
              <div className="form-group row col-md-12">
                <label className="col-md-6 col-lg-3">Pay Rate</label>
                <div className="col-md-6 col-lg-3">
                  <input type="number" className="form-control" name="payRate" id="payrate" value={this.state.payRate} size="5" onChange={this.handleInputChange} />
                </div>
                <label className="col-md-6 col-lg-3">Hours</label>
                <div className="col-md-6 col-lg-3">
                  <input type="number" className="form-control" name="hours" id="hours" value={this.state.hours} size="5" onChange={this.handleInputChange} />
                </div>
              </div>
              <div className="form-group row col-md-12">
                <label className="col-md-6 col-lg-3">OT Rate</label>
                <div className="col-md-6 col-lg-3">
                  <input type="number" className="form-control" name="otRate" value={this.state.otRate} id="otrate" size="5" onChange={this.handleInputChange} />
                </div>
                <label className="col-md-6 col-lg-3">OT Hours</label>
                <div className="col-md-6 col-lg-3">
                  <input type="number" className="form-control" name="otHours" id="othours" value={this.state.otHours} size="5" onChange={this.handleInputChange} />
                </div>
              </div>
              <div className="form-group row col-md-12">
                <label className="col-md-6 col-lg-3 ">OT Hourly</label>
                <div className="col-md-6 col-lg-3 float-left">
                  <input type="number" className="form-control" name="otHourly" id="othourly" value={this.state.otHourly} size="5" disabled />
                </div>
              </div>
              <div className="form-group row col-md-12">
                <label className="col-md-6 col-lg-3">Gross Pay</label>
                <div className="col-md-6 col-lg-3">
                  <input type="number" className="form-control" name="gross" id="grossPay" value={this.state.grossPay} size="5" disabled />
                </div>
              </div>
              <div className="form-group row col-md-12">
                <label className="col-md-6 col-lg-3">Tax Rate</label>
                <div className="col-md-6 col-lg-3">
                  <input type="number" className="form-control" name="taxRate" value={this.state.taxRate} id="taxrate" size="5" onChange={this.handleInputChange} />
                </div>
                <label className="col-md-6 col-lg-3">Total Taxes</label>
                <div className="col-md-6 col-lg-3">
                  <input type="number" className="form-control" name="totalTaxes" id="totaltax" value={this.state.totalTaxes} size="5" disabled />
                </div>
              </div>
              <div className="form-group row col-md-12">
                <label className="col-md-6 col-lg-3">Deductions</label>
                <div className="col-md-6 col-lg-3">
                  <input type="number" className="form-control" name="deductions" value={this.state.deductions} id="deductions" size="5" onChange={this.handleInputChange} />
                </div>
              </div>
              <div className="form-group row col-md-12">
                <label className="col-md-6 col-lg-3">Paycheck</label>
                <div className="col-md-6 col-lg-3">
                  <input type="number" className="form-control" name="payCheck" id="totalpay" value={this.state.payCheck} size="5" disabled />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Calculator;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        template
        title
        type
        red_track
        description
      }
    }
  }
`;